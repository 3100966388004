<template>
  <div class="file" ref="fileContainer">
    <!-- 搜索栏目 -->
    <el-form :inline="true" :model="search" class="demo-form-inline" label-position="left">
      <el-form-item label="" class="form_item_input_search" :style="search.searchInput">
        <el-input v-model="search.filename" placeholder="请输入文件名"  class="input_search" :style="search.searchInput"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-upload" @click="openUpload">上传</el-button>
      </el-form-item>
    </el-form>
    <!-- 面包屑 -->
    <span class="root-dic el-icon-thumb" @click="returnRoot">回到根目录</span>
    <el-divider direction="vertical" class="dic-dividing-line"></el-divider>
    <el-button type="text" class="el-icon-circle-plus-outline" @click="createRootFolder" :disabled="createFolderDisabled">创建目录</el-button>
    <el-divider direction="vertical" class="dic-dividing-line"></el-divider>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item v-for="(breadcrumbItem, index) in breadcrumbItems" :key="index">
          <span class="breadcrumb-text" :title="breadcrumbItem.name" @click="chooseFolder(breadcrumbItem.name, 0, breadcrumbItem.folderId, 'navicat')">{{ breadcrumbItem.name }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 文件列表 -->
    <div class="create-btn" v-if="showCreateButton">
      <span plain icon="el-icon-plus">暂无数据</span>
    </div>
    <ul class="infinite-list" v-infinite-scroll="loadData" style="overflow:auto;" v-loading.fullscreen.lock="fullscreenLoading" infinite-scroll-disabled="true" v-else>
       <div v-for="item in files" :key="item.id" @click="chooseFolder(item.name, item.type, item.id, 'local')" class="infinite-item">
          <img :src="item.type === 0 ? preFolderIcon : preFileIcon" alt="icon" class="pre-icon-img folder-info" />
          <span class="folder-info" :title="item.name" :data-mark="item.id+'-'+item.type" v-if="item.type === 0" v-contextmenu:folderMenu @contextmenu="showContextMenu($event)">{{item.name}}</span>
          <span class="folder-info" :title="item.name" :data-mark="item.id+'-'+item.type" v-else @click="showDrawer(item.id, item.name, item.filePath, item.fileSize)" v-contextmenu:fileMenu @contextmenu="showContextMenu($event)">{{item.name}}</span>
       </div>
       <el-button v-if="!scrollPage.noMore" type="text" style="font-family: monospace;" @click="loadData">点击加载更多</el-button>
    </ul>
    <!-- 右键菜单项 -->
    <v-contextmenu ref="folderMenu">
        <v-contextmenu-item @click="rename">重命名</v-contextmenu-item>
        <v-contextmenu-item @click="openUploadToAssignFolder">上传文件</v-contextmenu-item>
        <v-contextmenu-item @click="move">移动</v-contextmenu-item>
        <v-contextmenu-item @click="del">删除</v-contextmenu-item>
    </v-contextmenu>
    <v-contextmenu ref="fileMenu">
        <v-contextmenu-item @click="rename">重命名</v-contextmenu-item>
        <v-contextmenu-item @click="downloadFile">下载</v-contextmenu-item>
        <v-contextmenu-item @click="move">移动</v-contextmenu-item>
        <v-contextmenu-item @click="del">删除</v-contextmenu-item>
    </v-contextmenu>
    <!-- 右侧预览抽屉 -->
    <el-drawer :title="previewTitle" :visible.sync="drawer" size="100%">
      <div class="preview_container" :style="previewStyle">
        <h4 v-if="showDefaultMessage">该文件类型暂不支持进行预览</h4>
        <iframe :src="previewUrl" frameborder="0" style="width: 100%; height: 100%" v-else></iframe>
      </div>
    </el-drawer>
    <!-- 文件上传组件 -->
    <el-dialog title="文件上传" :visible.sync="showUpload">
      <el-select v-model="select.selectedNodeId" placeholder="请选择目的文件夹" :disabled="select.disabled" ref="selectUpResId">
        <el-option :value="select.selectedNodeId" :label="select.selectLabel" style="height: auto">
          <template>
            <el-tree :data="select.treeData" 
                node-key="id" 
                highlight-current
                :expand-on-click-node="false"
                :check-on-click-node="true"
                :check-strictly="true"
                @node-click="handleNodeClick">
            </el-tree>
          </template>
        </el-option>
      </el-select>
      <el-upload class="upload-component" 
          multiple 
          name="files"
          :action="uploadConfig.url"
          :headers="uploadConfig.headers"
          :data="uploadConfig.data"
          :before-upload="uploadBefore"
          :on-success="handleSuccess"
          :on-error="uploadErrorHandler"
          :on-remove="handleRemove">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">单文件大小小于100MB,当次总大小必须小于300MB</div>
      </el-upload>
    </el-dialog>
    <!-- 新建目录操作对话框 -->
    <el-dialog :title="dialog.title" :visible.sync="dialog.operationDiaglogVisible">
      <el-form :model="dialog.form">
        <div v-if="dialog.type == 'folder'">
          <el-form-item label="文件夹名称">
            <el-input v-model="dialog.form.folder.name" autocomplete="off" placeholder="请输入文件夹名称"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.operationDiaglogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureOperation">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 移动目录操作对话框 -->
    <el-dialog title="移动" :visible.sync="moveDialog.showMove" width="300px">
      <el-select v-model="moveDialog.selectedNodeId" placeholder="请选择目的文件夹" ref="selectUpMoveResId">
        <el-option :value="moveDialog.selectedNodeId" :label="moveDialog.selectLabel" style="height: auto">
          <template>
            <el-tree :data="select.treeData" 
                node-key="id" 
                highlight-current
                :expand-on-click-node="false"
                :check-on-click-node="true"
                :check-strictly="true"
                @node-click="handleNodeMoveClick">
            </el-tree>
          </template>
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="moveDialog.showMove = false">取 消</el-button>
        <el-button type="primary" @click="moveOperation">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import reqUtil from '@/util/ReqUtil.js';
import previewExts from '@/util/supportFilePreviewType'

export default {
  name: 'FileManage',
  props: {
    
  },
  data() {
    return {
      fullscreenLoading: false,
      search: {
          searchInput: {},
          filename: ''
      },
      preFolderIcon: require("../images/folder.png"),
      preFileIcon: require("../images/file.png"),
      files: [],
      breadcrumbItems: [
        {
          "folderId": null,
          "name": "/"
        }
      ],
      currentFolderId: null,
      drawer: false,
      previewTitle: '',
      currentChooseFileId: '',
      currentChooseFileName: '',
      currentChooseFileType: '',
      currentChooseNode: null,
      previewUrl: '',
      showDefaultMessage: false,
      previewStyle: {},
      showCreateButton: false,
      showUpload: false,
      createFolderDisabled: false,
      select: {
        selectedNodeId: null,
        selectLabel: null,
        disabled: false,
        treeData: []
      },
      uploadConfig: {
        headers: {
            'Authorization': localStorage.getItem("token")
        },
        url: reqUtil.http_domain + "/file/upload",
        data: {
          type: 1,
          parentId: null
        }
      },
      scrollPage: {
        page: 1,
        noMore: false
      },
      dialog: {
        operationDiaglogVisible: false,
        type: 'folder',
        title: '创建文件夹',
        form: {
          folder: {
            name: ''
          }
        }
      },
      moveDialog: {
        showMove: false,
        selectedNodeId: '',
        selectLabel: ''
      }
    }
  },
  methods: {
    onSubmit() {
      if (!this.search.filename) {
          this.$message("请输入文件名进行搜索")
      } else {
        this.fullscreenLoading = true;
        reqUtil.getFileList(null, 1, 50, this.search.filename).then((resp) => {
          this.files = resp.records;
          if (resp.current == resp.pages) {
            this.scrollPage.noMore = true;
          }
          this.fullscreenLoading = false;
          this.currentFolderId = -1;
          this.createFolderDisabled = true;
          this.breadcrumbItems = [
            {
              "folderId": null,
              "name": "/"
            }
          ]
        }).catch(() => {
          this.fullscreenLoading = false;
          this.$notify({
            title: '提示',
            message: '获取文件列表数据失败！',
            type: 'error'
          });
        })
      }
    },
    handleResize() {
      //计算文件搜索框的width属性
      const element = this.$refs.fileContainer;
      if (element != null) {
        const width = element.offsetWidth * 0.38;
        this.search.searchInput = {'width': width + 'px'};
      }
    },
    loadData() {
      // 获取文件的数据
      if (!this.scrollPage.noMore) {
        this.fullscreenLoading = true;
        this.scrollPage.page += 1;
        reqUtil.getFileList(this.currentFolderId, this.scrollPage.page, 50, this.search.filename).then((resp) => {
          if (resp.current == resp.pages) {
            this.scrollPage.noMore = true;
          }
          if (resp.records.length > 0) {
            const newFiles = [...this.files, ...resp.records];
            this.files = newFiles;
          }
          this.fullscreenLoading = false;
          if (this.files && this.files.length > 0) {
            this.showCreateButton = false;
          } else {
            this.showCreateButton = true;
          }
        });
      }
    },
    returnRoot() {
      this.chooseFolder("/", 0, null, "navicat");
      this.createFolderDisabled = false;
    },
    chooseFolder(name, type, fileId, clickOrigin) {
      if (type == 0) {
        if (this.currentFolderId !== fileId) {
          this.fullscreenLoading = true;
          if (fileId == null) {
            this.createFolderDisabled = false;
          }
          reqUtil.getFileList(fileId, 1, 50, null).then((resp) => {
            this.files = resp.records;
            if (resp.current == resp.pages) {
              this.scrollPage.noMore = true;
            }
            this.fullscreenLoading = false;
            if (clickOrigin === 'local') {
              this.breadcrumbItems.push({
                folderId: fileId,
                name: name
              });
            } else {
              const newBreadcrumbItems = [];
              for (let i = 0; i < this.breadcrumbItems.length; i++) {
                newBreadcrumbItems.push(this.breadcrumbItems[i]);
                if (this.breadcrumbItems[i].folderId === fileId) {
                  this.breadcrumbItems = newBreadcrumbItems;
                  break;
                }
              }
            }
            this.currentFolderId = fileId;
            if (resp.records.length > 0) {
              this.showCreateButton = false;
            }
          }).catch(() => {
            this.fullscreenLoading = false;
            this.$notify({
              title: '提示',
              message: '获取文件列表数据失败！',
              type: 'error'
            });
          })
        }
      }
    },
    showDrawer(fileId, fileName, filePath, size) {
      if (filePath == null || size == null) {
        this.$message("文件已损坏/丢失预览失败");
      } else {
        console.log(size)
        let unit = "B";
        const intNumber = parseInt(size);
        let sizeOfUnit = size;
        if ( intNumber >= 1_024 && intNumber < 1_048_576) {
          unit = "KB";
          sizeOfUnit = (size / 1024).toFixed(2);
        } else if (intNumber >= 1_048_576 && intNumber < 1_073_741_824) {
          unit = "MB";
          sizeOfUnit = (size / 1024 / 1024).toFixed(2);
        } else if (intNumber >= 1_073_741_824) {
          unit = "GB";
          sizeOfUnit = (size / 1024 / 1024 / 1024).toFixed(2);
        }
        this.previewTitle = `文件预览 [ ${fileName} - { ${sizeOfUnit} ${unit} } ]`;
        const index = fileName.lastIndexOf(".")
        let showDefault = true;
        if (index !== -1) {
          if (previewExts.includes(fileName.substring(index + 1).toLowerCase())) {
            showDefault = false;
            this.previewUrl = filePath;
            this.showDefaultMessage = false;
            this.previewStyle = {};
          }
        }
        if (showDefault) {
          this.previewUrl = '';
          this.showDefaultMessage = true;
          this.previewStyle = {
            "display": "flex",
            "justify-content": "center",
            "align-items": "center"
          }
        }
        this.drawer = true;
      }
    },
    openUpload() {
      this.showUpload = true;
      this.select.selectedNodeId = null;
      this.select.selectLabel = null;
      this.select.disabled = false;
    },
    openUploadToAssignFolder() {
      this.showUpload = true;
      this.select.selectedNodeId = this.currentChooseFileId;
      this.select.selectLabel = this.currentChooseFileName;
      this.select.disabled = true;
    },
    handleNodeClick(data) {
      this.select.selectedNodeId = data.id;
      this.select.selectLabel = data.label;
      // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
      this.$refs.selectUpResId.blur()
    },
    handleNodeMoveClick(data) {
      this.moveDialog.selectedNodeId = data.id;
      this.moveDialog.selectLabel = data.label;
      // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
      this.$refs.selectUpMoveResId.blur()
    },
    uploadBefore() {
      if (this.select.selectedNodeId == null) {
        this.$message("请选择上传的目录");
        return false;
      } else {
        this.uploadConfig.data.parentId = this.select.selectedNodeId;
        return true;
      }
    },
    handleSuccess() {
      //处理成功之后的回调
    },
    uploadErrorHandler() {
      this.$notify({
          title: '提示',
          message: '文件上传失败！',
          type: 'error'
        });
    },
    handleRemove(file) {
      const data = [];
      const info = {
        "id": file.response.data[0],
        "type": 1
      }
      data.push(info);
      reqUtil.delFileOrFolder(data).then(() => {
        this.$notify({
          title: '提示',
          message: '删除成功！',
          type: 'success'
        });
      }).catch(() => {
        this.$notify({
          title: '提示',
          message: '删除失败！',
          type: 'error'
        });
      });
    },
    del() {
      //删除文件或者文件夹
      const data = [];
      const info = {
        "id": this.currentChooseFileId,
        "type": this.currentChooseFileType
      }
      data.push(info);
      reqUtil.delFileOrFolder(data).then(() => {
        if (this.currentFolderId !== -1) {
          this.refreshFileList(this.currentFolderId);
        }
        this.$notify({
          title: '提示',
          message: '删除成功！',
          type: 'success'
        });
      }).catch(() => {
        this.$notify({
          title: '提示',
          message: '删除失败！',
          type: 'error'
        });
      });
      //每次删除之后判断是否删除完毕
      this.checkShowDefaultBtn();
    },
    rename() {
      const oldName = this.currentChooseNode.textContent;
      this.$prompt('请输入新名称', '重命名', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: oldName,
          inputPlaceholder: "请输入文件名称",
          inputPattern: /^[a-zA-Z0-9\u4e00-\u9fa5\-_.]+$/u,
          inputValidator: function(value) {
            if (oldName === value.trim()) {
              return "前后文件名不能相同";
            }
          },
          inputErrorMessage: '文件名不能为空并且不能包含特殊字符'
        }).then(({ value }) => {
          reqUtil.reName(this.currentChooseFileId, value)
          .then(() => {
            this.currentChooseNode.textContent = value;
            this.$notify({
              title: '提示',
              message: '修改成功！',
              type: 'success'
            });
          })
          .catch(() => {
            this.$notify({
              title: '提示',
              message: '修改失败！',
              type: 'error'
            });
          });
        }).catch(() => {});
    },
    move() {
      this.moveDialog.showMove = true;
      this.moveDialog.selectedNodeId = null;
      this.moveDialog.selectLabel = null;
    },
    moveOperation() {
      if (this.moveDialog.selectedNodeId === null) {
        this.$notify({
          title: '提示',
          message: '请选择目的目录',
          type: 'error'
        });
        return;
      }
      if (this.moveDialog.selectedNodeId == this.currentChooseFileId) {
        this.$notify({
          title: '提示',
          message: '不能从当前文件夹移动到当前文件夹',
          type: 'error'
        });
        return;
      }
      reqUtil.move(this.currentChooseFileId, this.moveDialog.selectedNodeId)
      .then(() => {
          this.moveDialog.showMove = false;
          this.$notify({
            title: '提示',
            message: '移动成功！',
            type: 'success'
          });
          this.refreshFileList(this.currentFolderId);
      })
      .catch(() => {
        this.$notify({
          title: '提示',
          message: '移动失败',
          type: 'error'
        });
      });
    },
    downloadFile() {
      reqUtil.download(this.currentChooseFileId).then(() => {
        this.$notify({
          title: '提示',
          message: '下载成功！',
          type: 'success'
        });
      }).catch(() => {
        this.$notify({
          title: '提示',
          message: '下载失败！',
          type: 'error'
        });
      });
    },
    showContextMenu(event) {
      //获取当前右键所在的元素的信息
      const info = event.target.getAttribute("data-mark");
      const index = info.indexOf("-");
      this.currentChooseFileId = info.substring(0, index);
      this.currentChooseFileType = info.substring(index + 1);
      this.currentChooseFileName = event.target.textContent;
      this.currentChooseNode = event.target;
    },
    checkShowDefaultBtn() {
      if (this.files && this.files.length > 0) {
        this.showCreateButton = false;
      } else {
        this.showCreateButton = true;
      }
    },
    createRootFolder() {
      //创建目录
      this.dialog.operationDiaglogVisible = true;
      this.dialog.type = "folder";
      this.dialog.title = "创建文件夹";
    },
    sureOperation() {
      //新建文件夹
      if (this.dialog.type == "folder") {
        if (!this.dialog.form.folder.name) {
          this.$message("文件夹名称不能为空");
        } else {
          reqUtil.createFolder(this.dialog.form.folder.name, this.currentFolderId).then(() => {
            this.$notify({
              title: '提示',
              message: '新建成功！',
              type: 'success'
            });
            this.dialog.operationDiaglogVisible = false;
            this.scrollPage.page = 1;
            this.scrollPage.noMore = false;
            this.refreshFileList(this.currentFolderId);
            this.refreshFolderTree();
          }).catch(() => {
            this.$notify({
              title: '提示',
              message: '新建失败！',
              type: 'error'
            });
          });
        }
      }
    },
    refreshFileList(folderId) {
      //刷新文件列表
      reqUtil.getFileList((folderId == null || folderId == undefined ? null : folderId), 1, 50, null).then((resp) => {
        this.files = resp.records;
        if (resp.current == resp.pages) {
          this.scrollPage.noMore = true;
        }
        if (this.files && this.files.length > 0) {
          this.showCreateButton = false;
        } else {
          this.showCreateButton = true;
        }
        //重置
        if (folderId == undefined || folderId == null) {
          this.breadcrumbItems = [
                    {
                      "folderId": null,
                      "name": "/"
                    }
          ]
          this.currentFolderId = null;
        }
      }).catch(() => {
        this.$notify({
          title: '提示',
          message: '获取文件列表数据失败！',
          type: 'error'
        });
      })
    },
    refreshFolderTree() {
      //获取目录树的数据
      reqUtil.getFolderTree().then((resp) => {
              this.select.treeData = resp;
       });
    } 
  },
  beforeMount() {
    reqUtil.checkLogin().then(result => {
        if (!result) {
          this.$router.push("/")
        }
      });
    // 获取文件的数据
    this.fullscreenLoading = true;
    reqUtil.getFileList(null, 1, 50, null).then((resp) => {
      this.files = resp.records;
      if (resp.current == resp.pages) {
        this.scrollPage.noMore = true;
      }
      this.fullscreenLoading = false;
      if (this.files && this.files.length > 0) {
        this.showCreateButton = false;
      } else {
        this.showCreateButton = true;
      }
    }).catch(() => {
      this.fullscreenLoading = false;
      this.$notify({
        title: '提示',
        message: '获取文件列表数据失败！',
        type: 'error'
      });
    })
  },
  mounted() {
    //监听浏览器页面大小的变化
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    //获取目录树的数据
    reqUtil.getFolderTree().then((resp) => {
      this.select.treeData = resp;
    });
  }
}
</script>

<style scoped>

  .el-form {
    position: initial;
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    background-color: initial;
  }

  .fileShowContainer {
    background-color: #e9eef3
  }

  .directory_item {
    pointer-events: none;
    user-select: none;
  }

  .infinite-list {
    padding-left: 0px;
    height: 500px;
  }

  .pre-icon-img {
    width: 25px;
    height: 25px;
    position: relative;
    top: 5px;
    padding-right: 10px;
  }

  .folder-info {
    cursor: pointer;
    display: inline-block;
    max-width: 600px; /* 文本最大宽度,超过600显示隐藏 */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .breadcrumb-text:hover{
    cursor: pointer;
  }

  .breadcrumb {
    display: inline-block;
    position: relative;
    top: 1.5px
  }

  .root-dic, .breadcrumb-text {
    font-size: 13px;
    color: #0000ff6b;
  }

  .root-dic {
    cursor: pointer;
  }

  .el-divider {
    background-color:cadetblue
  }

  .dic-dividing-line {
    margin-right: 16px;
    margin-left: 16px;
  }

  .infinite-item {
    width: fit-content; /* 或者使用 width: max-content; */
  }

  .preview_container {
    width: 100%;
    height: 100%;
  }

  .create-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
  }

  .upload-component {
    margin-top: 30px;
  }

</style>
