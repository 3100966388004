<template>
  <div class="home">
     <video 
        class="background-video" 
        autoplay 
        muted 
        loop 
        :src="src">
     </video>
     <h1 class="welcome-text">Welcome to the Personality Center</h1>
  </div>
</template>

<script>
import reqUtil from '@/util/ReqUtil.js'

export default {
  name: 'HomeComponent',
  props: {
    msg: String
  },
  data: function() {
    return {
      src: "",
      resourceName: "2c4b4b9adea94ecbb21312c1e2c1030f.mp4"
    }
  },
  beforeMount() {
    reqUtil.checkLogin().then(result => {
        if (!result) {
          this.$router.push("/")
        }
      });
  },
  mounted() {
    reqUtil.getCommonResourcePath().then(result => {
      this.src = result + this.resourceName;
    });
  } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .home {
    width: inherit;
    height: 100%;
  }

  .background-video {
    position: relative;
    width: 100%;
    height: inherit;
    object-fit: cover;
  }

  .welcome-text {
    text-transform: uppercase;
    color: white;
    font-family: monospace,sans-serif;
    font-size: 7em;
    text-align: center;
    top: 15vh;
    position: absolute;
    z-index: 10;
    mix-blend-mode: difference;
  }

</style>
