import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//右键菜单依赖
import contextmenu from 'v-contextmenu';
import 'v-contextmenu/dist/index.css';

import App from './App.vue'
import router from '@/routers/router'

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(contextmenu);

// 设置标题
router.beforeEach((to, from, next) => {
  //if (to.meta.title) {
    document.title = "个人技术分享"
  //}
  next()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
