<template>
  <div id="app"> 
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  },
  data() {
      return {
      
      };
    },
    methods: {

    },
    computed: {
      
    },
    beforeMount() {
      
    },
    mounted() {
    }

}
</script>

<style>
  .main-container {
    background-image: url("./images/app_background.png");
    background-size: cover; 
  }

  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 30px;
    flex-shrink: 0; /* 让头部和底部固定高度，不参与竖向布局 */
  }
  
  .el-main {
    background-color: #E9EEF3;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* 设置高度为 100vh，即占满整个屏幕 */
    box-sizing: border-box; /* 设置 box-sizing 为 border-box，让 padding 和 border 不会撑开容器 */
  }

  html, body {
    height: 100%; /* 让 html 和 body 占满整个屏幕 */
    margin: 0;
    padding: 0;
  }

  /* 表单样式 */
  .login-form-main {
    width: 480px;
    height: 503px;
  }

  .el-form {
      position: absolute; /* 设置为绝对定位 */
      top: 58px; /* 确保垂直方向上与容器顶部对齐，根据需要进行调整 */
      right: 100px; /* 从右侧距离100px */
      padding: 10px; /* 可选：设置内边距以增加内容与边框之间的间隔 */
      background-color: #fff; /* 设置整个 el-form 的背景颜色为白色 */
      padding-top: 49px;
      padding-bottom: 23px;
      padding-right: 46px;
   }

   .el-form-item {
     padding-bottom: 10px
   }

   .form-container {
      display: flex;
      align-items: center;
   }

   .verification_codde_input {
      flex: 1;
      margin-right: 10px;
      width: 150px;
      position: relative;
      left: -14%;
   }

   .verification_code_img {
      flex-shrink: 0;
      width: 130px;
      height: 50px;
      object-fit: contain;
   }

   .custom-button {
      width: 100%;
   }

   .operation-item {
      padding-top: 14px;
   }

   .verification_code_item .el-form-item__error {
      left: -14%
   }

</style>
