<template>
  <div id="facade">
    <el-container class="container">
      <el-header>
        <!-- router 开启菜单路由，开启之后，el-menu-item的index属性就会变成路由的path进行跳转 -->
        <el-menu :default-active="getActiveItem()" router class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#1d2939" text-color="#fff" active-text-color="#ffd04b">
          <el-menu-item index="0" id="logo-info" disabled>
            <!-- 方式一：使用require函数 -->
            <img :src="logo" style="width: 50px; height: 50px;" />
            <!-- 方式二： 如果想直接写相对路径，那么静态资源必须放在public文件夹下面, 
              因为： Vue CLI默认将public目录下的所有文件复制到打包后的根目录下。因此，如果您的图片文件不在public目录下，可能会出现路径错误的问题。 -->
            <!-- <img src="imgs/navigation.png" style="width: 80px; height: 30px;" /> -->
          </el-menu-item>
          <el-menu-item index="/home">主页</el-menu-item>
          <!-- <el-submenu index="2">
            <template slot="title">攻防演练</template>
            <el-menu-item index="2-1">演练态势</el-menu-item>
            <el-menu-item index="2-2">演练管理</el-menu-item>
          </el-submenu> -->
          <el-menu-item index="/user">用户管理</el-menu-item>
          <el-menu-item index="/files" v-if="validRouePermission()">文件管理</el-menu-item>
          <!-- <el-menu-item index="4">威胁检测</el-menu-item>
          <el-menu-item index="5">分析中心</el-menu-item>
          <el-menu-item index="6">处置响应</el-menu-item>
          <el-menu-item index="7">日志检索</el-menu-item>
          <el-menu-item index="8">资产中心</el-menu-item>
          <el-menu-item index="9">报表管理</el-menu-item>
          <el-submenu index="10">
            <template slot="title">数据采集</template>
            <el-submenu index="10-1">
              <template slot="title">平台监控</template>
              <el-menu-item index="10-1-1">状态监控</el-menu-item>
              <el-menu-item index="10-1-2">积压监控</el-menu-item>
            </el-submenu>
            <el-submenu index="10-2">
              <template slot="title">数据接入</template>
              <el-menu-item index="10-2-1">采集器管理</el-menu-item>
              <el-menu-item index="10-2-2">采集配置</el-menu-item>
            </el-submenu>
            <el-menu-item index="10-3">数据处理</el-menu-item>
            <el-menu-item index="10-4">规则管理</el-menu-item>
            <el-submenu index="10-5">
              <template slot="title">插件管理</template>
              <el-menu-item index="10-5-1">解析插件</el-menu-item>
              <el-menu-item index="10-5-2">动态富化函数</el-menu-item>
            </el-submenu>
            <el-menu-item index="10-6">数据管理</el-menu-item>
            <el-menu-item index="10-7">日志类型</el-menu-item>
            <el-submenu index="10-8">
              <template slot="title">参数管理</template>
              <el-menu-item index="10-8-1">Kafka参数</el-menu-item>
              <el-menu-item index="10-8-2">ElasticSearch参数</el-menu-item>
              <el-menu-item index="10-8-3">系统参数</el-menu-item>
            </el-submenu>
          </el-submenu> -->
        </el-menu>
        <div class="header-user-info">
          <el-badge :value="0" :max="99" class="msg-info">
            <el-button type="info" icon="el-icon-bell" size="small" circle></el-button>
          </el-badge>
          <el-divider direction="vertical" class="dividing-line"></el-divider>
          <el-dropdown @command="handlerUser">
            <span class="el-dropdown-link">
              <span id="username" v-text="userInfo.name"></span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="help" icon="el-icon-info">帮助</el-dropdown-item>
              <el-dropdown-item command="editInfo" icon="el-icon-edit">编辑信息</el-dropdown-item>
              <el-dropdown-item command="logout" icon="el-icon-switch-button">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        
        <!-- 下面是一些弹框信息 -->
        <!-- 1.帮助信息的弹框 -->
        <el-dialog title="帮助" :visible.sync="helpDialogVisible" width="30%" id="help-dialog">
          <span>这是一段帮助信息</span>
          <span slot="footer" class="dialog-footer"></span>
        </el-dialog>

      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>  
      <el-footer height="32px">
        <span style="color: rgba(0,0,0,.25);font-size: 10px;">
            Copyright © 2023-2024 个人学习网站
            <a href="https://beian.miit.gov.cn/" style="color: rgba(41, 32, 32, 0.25);font-size: 10px; text-decoration: none;" target="_blank">蜀ICP备19003800号-1</a>
        </span> 
      </el-footer>
    </el-container>  
  </div>
</template>

<script>
import reqUtil from '@/util/ReqUtil'

export default {
  name: 'FacadeMain',
  components: {
    
  },
  data() {
      return {
        logo: require("../images/navigation.png"),
        activeIndex: '1',
        username: "",
        helpDialogVisible: false,
        userInfo: {}
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      handlerUser(params) {
        if (params === "help") {
          this.helpDialogVisible = true;
        } else if (params === "editInfo") {
          this.$message("待完善功能: 编辑信息");
        } else if (params === "logout") {
           //this.$message("待完善功能: 退出登录");
           reqUtil.logout().then(() => {
            this.userInfo = {};
            localStorage.removeItem('token');
            this.$router.push("/")
          }).catch(error => {
            // 请求失败的处理逻辑
            console.error(error);
          })
        }
      },
      validRouePermission() {
          //根据路由中的路径信息进行权限控制，动态控制菜单的显示
          // console.log(this.$router.options.routes)
          // const paths = this.$router.options.routes.map(routeObj => routeObj.path);
          // console.log(paths)
          // return paths.includes(this.$route.path);
          return true
      },
      getActiveItem() {
        if (this.$route.path === '/facade') {
          return '/home';
        }
        return this.$route.path;
      }
    },
    computed: {
      
    },
    beforeMount() {
      //挂载之前执行
      reqUtil.checkLogin().then(result => {
          if (!result) {
            this.$router.push("/")
          }
        });
      //渲染用户信息
      reqUtil.getUserInfo().then(userInfo => {
          this.userInfo = userInfo;
        }).catch(() => {
          console.log("获取用户信息失败")
        });
    },
    mounted() {
      if (this.$route.path === '/facade') {
        this.$router.push('/home');
      }
    }

}
</script>

<style scoped>
.el-header {
    background-color: #1d2939;
    color: #333;
    text-align: center;
    line-height: 60px;
    flex-shrink: 0; /* 让头部和底部固定高度，不参与竖向布局 */
    padding: 0
  }

  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 30px;
    flex-shrink: 0; /* 让头部和底部固定高度，不参与竖向布局 */
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  
  .el-main {
    background-color: #E9EEF3;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* 设置高度为 100vh，即占满整个屏幕 */
    box-sizing: border-box; /* 设置 box-sizing 为 border-box，让 padding 和 border 不会撑开容器 */
  }

  html, body {
    height: 100%; /* 让 html 和 body 占满整个屏幕 */
    margin: 0;
    padding: 0;
  }

  .header-user-info {
    position: fixed;
    text-align: right;
    top: 0;
    right: 0;
    width: 220px;
    padding: 0 20px;
    height: 60px;
    background: #1c2839;
  }

  .msg-info, .dividing-line {
    margin-right: 20px;
  }

  .msg-info .el-badge__content {
    position: relative;
    left: -26px;
  }

  /* logo部分的样式设置，不然会出现点击的效果 */
  #logo-info {
    pointer-events: none;
    color: inherit;
    background-color: transparent;
    cursor: default;
    opacity: 1;
  }

  #help-dialog .el-dialog__header {
    display: flex;
  }
</style>
