//配置支持的文件预览类型
export default [
    "pdf",
    "txt",
    "json",
    "jpg",
    "jepg",
    "png",
    "mp4",
    "gif",
    "csv",
    "log",
    "xml",
    "html",
    "htm",
    "css",
    "js",
    "ogg",
    "mp3",
    "aac"
]