<template>
  <div class="login">
    <el-container class="container">
      <el-main class="main-container">
        <div class="login-form-main">
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="form.password" placeholder="请输入密码" type="password"></el-input>
            </el-form-item>
            <!-- <el-form-item label="" label-width="120px" prop="verification_code" class="verification_code_item">
              <div class="form-container">
                <el-input v-model="form.verificationCode" class="verification_codde_input" placeholder="请输入验证码"></el-input>
                <img :src="verification_code_str" alt="验证码" class="verification_code_img" @click="switchVerificationCode" ref="verification_code_element"/>
              </div>
            </el-form-item> -->
            <el-form-item label="" class="verification_code_container">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="" prop="verificationCode">
                    <el-input v-model="form.verificationCode" class="verification_code_input" placeholder="请输入验证码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="">
                    <img :src="verification_code_str" alt="验证码" class="verification_code_img" :style="widthAndHeight"  @click="switchVerificationCode" ref="verification_code_element"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="operation-item">
              <el-button type="primary" @click="onSubmit" class="custom-button">登录</el-button>
            </el-form-item>
            <el-form-item class="errorMessageContainer">
              <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
            </el-form-item>
          </el-form>
        </div>
      </el-main>  
      <el-footer height="32px">
        <span style="color: rgba(41, 32, 32, 0.25);font-size: 10px;">
            Copyright © 2023-2024 个人学习网站
            <a href="https://beian.miit.gov.cn/" style="color: rgba(41, 32, 32, 0.25);font-size: 10px; text-decoration: none;" target="_blank">蜀ICP备19003800号-1</a>
          </span> 
      </el-footer>
    </el-container>
  </div>
</template>

<script>

import reqUtil from '@/util/ReqUtil'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'LoginComponent',
  components: {
    
  },
  data() {
      return {
        form: {
          username: '',
          password: '',
          verificationCode: ''
        },
        verification_code_str: '',
        rules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ],
          verificationCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        random_value:'',
        verification_code_element_width: '130',
        verification_code_element_height: '50',
        widthAndHeight: {
          width: this.verification_code_element_width + "px",
          height: this.verification_code_element_height + "px"
        },
        errorMessage: ''
      };
    },
    methods: {
      // 提交登录信息
      onSubmit: function() {
        const data = {
          "mark": this.random_value,
          "account": this.form.username,
          "pass": this.form.password,
          "verificationCode": this.form.verificationCode
        }
        reqUtil.login(data).then(token => {
          this.errorMessage = '';
          localStorage.setItem('token', token);
          this.$router.push("/facade");
        }).catch(error => {
          console.log(123)
          // 请求失败的处理逻辑
          if (error.response.data.message.includes("禁用")) {
            this.errorMessage = "用户已被锁定,请联系管理员进行解锁";
          } else {
            this.errorMessage = "登录失败, 请检查验证码、用户名、密码信息";
          }
        })
      },
      //切换验证码
      switchVerificationCode: function() {
        this.random_value = uuidv4();
        const data = {
          "_u": this.random_value,
          "width": this.verification_code_element_width,
          "height": this.verification_code_element_height,
        }
        reqUtil.getVerificationCode(data).then(verificationCodeStr => {
          this.verification_code_str = `data:image/png;base64,${verificationCodeStr}`
        })
      }
    },
    computed: {
      
    },
    beforeMount() {
      // 请求验证码
      this.random_value = uuidv4();
      const data = {
        "_u": this.random_value,
        "width": this.verification_code_element_width,
        "height": this.verification_code_element_height,
      }
      reqUtil.getVerificationCode(data).then(verificationCodeStr => {
        this.verification_code_str = `data:image/png;base64,${verificationCodeStr}`
      })
    },
    mounted() {

    }
}
</script>

<style scoped>
  .main-container {
    background-image: url("../images/app_background.png");
    background-size: cover; 
  }

  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 30px;
    flex-shrink: 0; /* 让头部和底部固定高度，不参与竖向布局 */
  }
  
  .el-main {
    background-color: #E9EEF3;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* 设置高度为 100vh，即占满整个屏幕 */
    box-sizing: border-box; /* 设置 box-sizing 为 border-box，让 padding 和 border 不会撑开容器 */
  }

  html, body {
    height: 100%; /* 让 html 和 body 占满整个屏幕 */
    margin: 0;
    padding: 0;
  }

  /* 表单样式 */
  .login-form-main {
    width: 480px;
    height: 503px;
  }

  .el-form {
      position: absolute; /* 设置为绝对定位 */
      top: 58px; /* 确保垂直方向上与容器顶部对齐，根据需要进行调整 */
      right: 100px; /* 从右侧距离100px */
      padding: 10px; /* 可选：设置内边距以增加内容与边框之间的间隔 */
      background-color: #fff; /* 设置整个 el-form 的背景颜色为白色 */
      padding-top: 49px;
      padding-bottom: 23px;
      padding-right: 46px;
   }

   .form-container {
      display: flex;
      align-items: center;
   }

   .verification_code_img {
      object-fit: contain;
      position: relative;
      left: 37px;
   }

   .custom-button {
      width: 100%;
   }

   .verification_code_container {
      margin-bottom: 0px;
   }

   .verification_code_container .el-input__inner {
      margin-top: 6px;
   }

   .errorMessageContainer {
      margin: 0px;
      padding: 0px;
   }

   .error-message {
    position: relative;
    top: -35px;
    color: red;
   }

</style>
