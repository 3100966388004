<template>
  <div class="user">
    <el-button class="user-add-button finger" icon="el-icon-plus" type="primary" size="small" @click="dialogOut">新增</el-button>
    <el-dialog title="新增用户" :visible.sync="user.dialogFormVisible">
      <el-form :model="user.form" :rules="user.form.rules" ref="formRef">
        <el-form-item label="账号" :label-width="user.form.formLabelWidth" prop="account">
          <el-input v-model="user.form.account"></el-input>
        </el-form-item>
        <el-form-item label="角色" :label-width="user.form.formLabelWidth" prop="role">
          <el-select v-model="user.form.role" placeholder="请选择角色">
            <el-option :label="item.name" :value="item.id" v-for="item in user.roles" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="user.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser" class="add-user-btn">新增</el-button>
      </div>
    </el-dialog>
    <el-popover placement="left" width="160" v-model="visible">
      <p>确定删除吗？</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="deleteUser">确定</el-button>
      </div>
      <el-button class="user-del-button finger" icon="el-icon-delete" type="danger" size="small" slot="reference">删除</el-button>
    </el-popover>
    <div class="search-info">
      <el-input placeholder="请输入用户姓名" v-model="searchCondition" class="input-with-select" clearable>
        <el-button slot="append" icon="el-icon-search" @click="searchByName" class="finger"></el-button>
      </el-input>
      <el-button circle icon="el-icon-refresh-right" @click="refreshTable" class="finger"></el-button>
    </div>
    <el-table :data="tableData" height="720" border sortable v-loading="loading" style="width: 100%" :default-sort = "{prop: 'lastTime', order: 'descending'}" @sort-change="sortMethod" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="account" label="账号"></el-table-column>
      <el-table-column prop="jobNumber" label="工号" sortable></el-table-column>
      <el-table-column prop="gender" label="性别">
        <template slot-scope="scope">
          <span>{{scope.row.gender == 0 ? "男" : "女"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="phoneNumber" label="电话"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{scope.row.status == 0 ? "已禁用" : "正常"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" label="角色"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" sortable show-overflow-tooltip>
        <template slot-scope="scope">
          {{ formatDate(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="lastTime" label="上次访问时间" sortable show-overflow-tooltip>
        <template slot-scope="scope">
          {{ formatDate(scope.row.lastTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="lastIp" label="上次访问IP" sortable show-overflow-tooltip></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-dropdown>
            <span class="el-dropdown-link">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button @click="handleEdit(scope.$index, scope.row, 'del')" type="text" size="small" icon="el-icon-delete">删除</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button @click="handleEdit(scope.$index, scope.row, 'disabled')" type="text" size="small" :disabled="scope.row.status === 0" icon="el-icon-turn-off">禁用</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button @click="handleEdit(scope.$index, scope.row, 'enable')" type="text" size="small" :disabled="scope.row.status !== 0" icon="el-icon-open">启用</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button @click="handleEdit(scope.$index, scope.row, 'reset')" type="text" size="small" icon="el-icon-refresh-left">重置密码</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      id="pagenation-info"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageTotal">
    </el-pagination>
    <!-- 弹框信息 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%">
      <span v-text="hintMessage"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cacel">取 消</el-button>
        <el-button type="primary" @click="operation">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import reqUtil from '@/util/ReqUtil.js'

export default {
  name: 'UserManage',
  props: {
    
  },
  data() {
    return {
      loading: true,
      searchCondition: '',
      currentPage: 1,
      visible: false,
      hintMessage: '确认执行操作？',
      dialogVisible: false,
      operationType: "",
      operationData: {},
      pageSize: 10,
      pageTotal: 0,
      tableData: [],
      sorts: [],
      user: {
        dialogFormVisible: false,
        form: {
          account: '',
          role: '',
          rules: {            
            account: [
              { required: true, message: '请输入账号', trigger: 'blur' }
            ],
            role: [
              { required: true, message: '请选择角色', trigger: 'blur' }
            ]
          }
        },
        formLabelWidth: '10px',
        validSuccess: true,
        roles: [],
      },
      tableSelectItems: []
    }
  },
  methods: {
    handleEdit(index, row, type) {
      this.operationType = type;
      this.operationData = row;
      if (this.operationType === 'del') {
        this.hintMessage = `确认删除用户{ ${row.name} }？`
      } else if (this.operationType === 'disabled') {
        this.hintMessage = `确认禁用用户{ ${row.name} }？`
      } else if (this.operationType === 'enable') {
        this.hintMessage = `确认启用用户{ ${row.name} }？`
      } else if (this.operationType === 'reset') {
        this.hintMessage = `确认重置用户{ ${row.name} }的密码？`
      }
      this.dialogVisible = true;
    },
    operation() {
      if (this.operationType === 'del') {
        const data = [];
        data.push(this.operationData.id);
        reqUtil.delUser(data).then(() => {
          this.$notify({
            title: '成功',
            message: '删除成功！',
            type: 'success'
          });
          this.refreshTable();
        }).catch(() => {
          this.$notify({
            title: '提示',
            message: '操作失败！',
            type: 'error'
          });
        })
       } else if (this.operationType === 'disabled') {
          reqUtil.disabledUser(this.operationData.id).then(() => {
            this.$notify({
              title: '成功',
              message: '禁用成功！',
              type: 'success'
            });
            this.refreshTable();
          }).catch(() => {
            this.$notify({
              title: '提示',
              message: '操作失败！',
              type: 'error'
            });
          })
       } else if (this.operationType === 'enable') {
          reqUtil.enableUser(this.operationData.id).then(() => {
              this.$notify({
                title: '成功',
                message: '启用成功！',
                type: 'success'
              });
              this.refreshTable();
            }).catch(() => {
              this.$notify({
                title: '提示',
                message: '操作失败！',
                type: 'error'
              });
            })
       } else if (this.operationType === 'reset') {
          reqUtil.resetUserPass(this.operationData.id).then(() => {
              this.$notify({
                title: '成功',
                message: '重置成功！',
                type: 'success'
              });
              this.refreshTable();
            }).catch(() => {
              this.$notify({
                title: '提示',
                message: '操作失败！',
                type: 'error'
              });
            })
       }
      this.operationType = '';
      this.operationData = {};
      this.dialogVisible = false;
    },
    cacel() {
      this.operationType = '';
      this.operationData = {};
      this.dialogVisible = false;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.refreshTable();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    formatDate(timestamp) {
      if (timestamp === null || timestamp === '') {
        return '';
      }
      const date = new Date(timestamp);
      const formattedDateTime = date.toLocaleString();
      return formattedDateTime;
    },
    searchByName() { 
      reqUtil.getUserList(1, this.pageSize, this.sorts, this.searchCondition).then(userList => {
        if (userList !== null) {
          this.tableData = userList.records;
          this.pageTotal = userList.total;
          this.currentPage = userList.pages;
        }
      }).catch(() => {
        this.$notify({
            title: '提示',
            message: '操作失败！',
            type: 'error'
          });
      });
    },
    refreshTable() {
      reqUtil.getUserList(1, this.pageSize, this.sorts, this.searchCondition).then(userList => {
        if (userList !== null) {
          this.tableData = userList.records;
          this.pageTotal = userList.total;
          this.currentPage = userList.pages;
        }
        this.loading = false;
      }).catch(() => {
        this.$message("操作失败");
        this.loading = false;
      });
    },
    sortMethod(column){
      const sortInfo = {
        "fieldName": this.camelToSnake(column.prop),
        "sort": column.order === "ascending"? "asc" : "desc"
      }
      this.sorts.splice(0, this.sorts.length);
      this.sorts.push(sortInfo);
      this.refreshTable();
    },
    dialogOut() {
      reqUtil.getRoleList().then(roles => {
        this.user.roles = roles;
      }).catch(error => {
         console.log(error)
      })
      this.user.dialogFormVisible = true;
    },
    addUser() {
      this.validateForm();
      if (this.user.validSuccess) {
        reqUtil.addUser(this.user.form.account, this.user.form.role).then(() => {
          this.user.dialogFormVisible = false;
          this.refreshTable();
      }).catch(() => {
        this.$message("操作失败")
      });
      }
    },
    deleteUser() {
      if (this.tableSelectItems.length <= 0) {
        this.$message("请选择待删除的项")
      } else {
        reqUtil.delUser(this.tableSelectItems).then(() => {
          this.$notify({
            title: '成功',
            message: '删除成功！',
            type: 'success'
          });
          this.refreshTable();
        }).catch(() => {
          console.error("操作失败")
        })
      }
      this.visible = false
    },
    camelToSnake(camelCase) {
      return camelCase.replace(/[A-Z]/g, function(match) {
        return '_' + match.toLowerCase();
      });
    },
    handleSelectionChange(selection) {
      if (selection.length === 0) {
        this.tableSelectItems = []
      } else {
        this.tableSelectItems = selection.map(user => {
          return user.id;
        })
      }
    },
    validateForm() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          this.user.validSuccess = false
        } else {
          this.user.validSuccess = true
        }
    });
  }
  },
  mounted() {
    
  },
  beforeMount() {
    //登录检查
    reqUtil.checkLogin().then(result => {
        if (!result) {
          this.$router.push("/")
        }
      });
    //加载表格数据  
    reqUtil.getUserList(this.currentPage, this.pageSize, this.sorts, this.searchCondition).then(userList => {
      if (userList !== null) {
        this.tableData = userList.records;
        this.pageTotal = userList.total;
        this.currentPage = userList.pages;
      }
      this.loading = false;
    }).catch(() => {
      this.$message("操作失败");
      this.loading = false;
    });
  }
}
</script>

<style scoped>

#pagenation-info {
  position: relative;
  text-align: right;
}

.search-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  margin-bottom: 12px;
}

.search-info .el-input {
  margin-right: 10px;
  width: 310px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.user-add-button {
  float: left;
  height: 32px;
  margin-top: 8px;
}

.user-del-button {
  float: left;
  height: 32px;
  margin-top: 8px;
  margin-left: 10px
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
.el-icon-arrow-down {
  font-size: 12px;
}

.el-form {
  position: relative;
  top: 0px;
  right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}
.el-form-item__content {
  display: grid;
}

.finger:hover {
  cursor: pointer;
}
</style>
