import Vue from 'vue'
import Router from 'vue-router'

import Login from '../components/Login.vue'
import FacadeMain from '../components/FacadeMain.vue'
import Home from '../components/Home.vue'
import User from '../components/UserManage.vue'
import FileManage from '../components/FileManage.vue'

Vue.use(Router)

// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  { 
    path: '/', 
    component: Login 
  },
  { 
    path: '/facade',
    component: FacadeMain , 
    children: [
      { path: '/home', component: Home },
      { path: '/user', component: User },
      { path: '/files', component: FileManage }
    ]
}
  
]
const router = new Router({
  routes
})
export default router
