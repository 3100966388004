import axios from "axios";  

let http_domain = "http://www.miaoxing.online:18627/oa"

/**
 * 获取验证码信息
 */
async function getVerificationCode(data) {
    try {
        const response = await axios.get(`${http_domain}/auth/verificationCode`, {
            params: data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.data.code == 200) {
            return response.data.data
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 登录请求
 */
async function login(data) {
    try {
        const response = await axios.post(`${http_domain}/auth/login`, data);
        if (response.data.code == 200) {
            return response.data.data
        }
    } catch (error) {
        console.error(error.message);
        console.error(error);
        throw error;
    }
}

/**
 * 获取用户信息
 */
async function getUserInfo() {
    try {
        const token = localStorage.getItem("token")
        const headers = {
            'Authorization': token
        }
        const response = await axios.post(`${http_domain}/user/getUser`, {}, {headers});
        if (response.data.code == 200) {
            return response.data.data
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 退出登录
 */
async function logout() {
    try {
        const token = localStorage.getItem("token")
        const headers = {
            'Authorization': token
        }
        const response = await axios.post(`${http_domain}/auth/logout`, {}, {headers});
        if (response.data.code == 200) {
            return response.data
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 检查登录
 */
async function checkLogin() {
    try {
        const token = localStorage.getItem("token")
        const headers = {
            'Authorization': token
        }
        const response = await axios.post(`${http_domain}/user/getUser`, {}, {headers});
        if (response.data.code == 200) {
            return true;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 获取公共资源路径
 */
async function getCommonResourcePath() {
    try {
        const token = localStorage.getItem("token")
        const response = await axios.get(`${http_domain}/common/getCommonResourcePath`, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        });
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<< 用户模块 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<

/**
 * 获取用户列表
 */
async function getUserList(page, size, sorts, searchCondition) {
    try {
        const token = localStorage.getItem("token")
        const headers = {
            'Authorization': token,
            'Content-Type': "application/json"
        }
        const data = {
            "page": page,
            "size": size,
            "sorts": sorts,
            "name": searchCondition
          }
        const response = await axios.post(`${http_domain}/user/getUserList`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 获取角色列表
 */
async function getRoleList() {
    try {
        const token = localStorage.getItem("token")
        const response = await axios.get(`${http_domain}/role/getRoleList`, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        });
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 新增用户
 */
async function addUser(account, roleId) {
    try {
        const token = localStorage.getItem("token")
        const headers = {
            'Authorization': token,
            'Content-Type': "application/json"
        }
        const data = {
            "account": account,
            "roleId": roleId
          }
        const response = await axios.post(`${http_domain}/user/addUser`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 删除用户
 */
async function delUser(userIds) {
    try {
        const token = localStorage.getItem("token")
        const response = await axios.delete(`${http_domain}/user/delete`, {
            data: userIds,
            headers: {
                'Authorization': token,
                'Content-Type': "application/json"
            }
        });
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 禁用用户
 */
async function disabledUser(userId) {
    try {
        const token = localStorage.getItem("token");
        const headers = {
            'Authorization': token
        };
        const data = {
            'userId': userId
        }
        const response = await axios.post(`${http_domain}/user/disabled`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 启用用户
 */
async function enableUser(userId) {
    try {
        const token = localStorage.getItem("token");
        const headers = {
            'Authorization': token
        };
        const data = {
            'userId': userId
        }
        const response = await axios.post(`${http_domain}/user/enable`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 重置密码
 */
async function resetUserPass(userId) {
    try {
        const token = localStorage.getItem("token");
        const headers = {
            'Authorization': token
        };
        const data = {
            'userId': userId
        }
        const response = await axios.post(`${http_domain}/user/reset-pass`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<< 文件管理模块 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<

/**
 * 获取文件列表
 */
async function getFileList(folderId, page, size, nameCondition) {
    try {
        const token = localStorage.getItem("token");
        const headers = {
            'Authorization': token
        };
        const data = {
            "page": page,
            "size": size,
            "name": nameCondition,
            "folderId": folderId
          };
        const response = await axios.post(`${http_domain}/file/getFiles`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 获取文件夹树
 */
async function getFolderTree() {
    try {
        const token = localStorage.getItem("token")
        const response = await axios.get(`${http_domain}/file/folderTree`, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        });
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 删除文件/文件夹
 */
async function delFileOrFolder(data) {
    try {
        const token = localStorage.getItem("token")
        const response = await axios.delete(`${http_domain}/file/del`, {
            data: data,
            headers: {
                'Authorization': token,
                'Content-Type': "application/json"
            }
        });
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 创建文件夹
 */
async function createFolder(name, parentId) {
    try {
        const token = localStorage.getItem("token");
        const headers = {
            'Authorization': token
        };
        const data = {
            "name": name,
            "destinationFolderId": parentId == null ? null : parentId
          };
        const response = await axios.post(`${http_domain}/file/createFolder`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 下载文件
 */
async function download(fileId) {
    try {
        const token = localStorage.getItem("token")
        const response = await axios.get(`${http_domain}/file/download`, {
            params: {
                "mark": fileId
            },
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
        if (response.status == 200) {
            // 解析文件名
            let filename = response.headers.get('Content-Disposition').split('=')[1];
            filename = decodeURIComponent(filename.replace(/\+/g, ' ')); 
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            // 创建一个 <a> 元素
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute('download', filename);
            // 触发点击事件进行下载
            downloadLink.click();
            // 清理创建的对象 URL
            window.URL.revokeObjectURL(downloadLink.href);
        }
    } catch (error) {
        return false;
    }
}

/**
 * 文件重命名
 */
async function reName(fileId, newName) {
    try {
        const token = localStorage.getItem("token");
        const headers = {
            'Authorization': token
        };
        const data = {
            "id": fileId,
            "newName": newName
          };
        const response = await axios.put(`${http_domain}/file/rename`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

/**
 * 文件移动
 */
async function move(originFileId, distFileId) {
    try {
        const token = localStorage.getItem("token");
        const headers = {
            'Authorization': token
        };
        const data = {
            "id": originFileId,
            "destinationFolderId": distFileId
          };
        const response = await axios.put(`${http_domain}/file/move`, data, {headers});
        if (response.data.code == 200) {
            return response.data.data;
        }
    } catch (error) {
        return false;
    }
}

export default {http_domain, getVerificationCode, login, getUserInfo, logout, checkLogin, 
    getCommonResourcePath, getUserList, getRoleList, addUser, delUser, disabledUser, enableUser, 
    resetUserPass, getFileList, getFolderTree, delFileOrFolder, createFolder, download, reName, move}